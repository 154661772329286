import {IHttpClient} from '@wix/fe-essentials-editor/src/exports/http-client/index';

export function markSiteHasCategoryPage(httpClient: IHttpClient, appInstance: string) {
  return httpClient.get('https://editor.wix.com/_serverless/wixstores-categories-rollout-manager/mark', {
    headers: {Authorization: appInstance},
  });
}

export function getCategoryPageStatusForSite(msid: string, httpClient: IHttpClient, appInstance: string) {
  return httpClient.get<{result: {value: boolean}}>(
    `https://editor.wix.com/_serverless/wixstores-categories-rollout-manager/siteRolloutStatus?metaSiteId=${msid}`,
    {headers: {Authorization: appInstance}}
  );
}
